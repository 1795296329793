import React from "react";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";

const PublicSports = () => {
  return (
    <>
      <Header />

      <div className="exchange_iframe">
        <iframe src="https://design.powerplay247.com/auth" />
      </div>

      <Footer />
      <BottomNav />
    </>
  );
};
export default PublicSports;

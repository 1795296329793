import React from "react";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import WhatsappContact from "../../../../assets/images/whatsapp-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

import ContactUsImg from "../../../../assets/images/contact1.png";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";

const ContactUsBefore = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <div>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="pagesHeading">
            <h2 className="sectionTitle border-0">Contact Us</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <main className=" footer-content">
            <div className="container">
              <div className="payment-info contact-pay-info">
                <div className="contact-img">
                  <img src={ContactUsImg} />
                </div>
                <div class="social-icons contact-social">
                  <ul>
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {/* <span className="notification-count">2</span> */}
                        <img src={Whatsapp} alt="Whatsapp Icon" />
                        <p>Whats App</p>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={Telegram} alt="Telegram Icon" />
                        <p> Telegram</p>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.INSTATGRAM != ""
                            ? appDetails?.INSTATGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                      >
                        <img src={Instagram} alt="Instagram Icon" />
                        <p>Instagram</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default ContactUsBefore;

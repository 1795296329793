import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from "react-bootstrap";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotsData } from "../../../../lib/data/slotsData";
import BackIcon from "../../../../assets/svg/BackIcon";

const Slots = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <div>
      <HeaderAfterLogin />

      <main className="main">
        <Container>
          <div className="slots-section">
            <div className="pagesHeading">
              <h4>Slots</h4>
              <a
                href="javascript:void(0)"
                className="back-link"
                onClick={() => navigate(-1)}
              >
                Back <BackIcon />
              </a>
            </div>

            <div className="games-section">
              <ul>
                {Array.isArray(slotsData) &&
                  slotsData.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.gameUrl}>
                          <img src={item.img} />
                          <div className="casino_overlay">
                            <img src={item.playImg} />
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Slots;

import React, { useEffect, useState } from "react";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { casinoSection as casinoData } from "../../../../lib/data/casinoSection.data";
import { auraGames } from "../../../../lib/data/auraGames.data";
import AuraGamesIcons from "../../../../assets/images/icons/auraa.png";
import VirtualCardsGames from "../../../../assets/images/icons/best-customer-experience.png";
import CardGamesIcon from "../../../../assets/images/icons/playing-cards.png";
import InstantGames from "../../../../assets/images/icons/rocket.png";
import ImprovedIcons from "../../../../assets/images/icons/augmented-reality.png";
import play from "../../../../assets/images/icons/multimedia.png";
import { Container } from "react-bootstrap";
import BackIcon from "../../../../assets/svg/BackIcon";

const AuraGames = () => {
  const casinoSection = { ...casinoData, ...auraGames };
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });

  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "Aura Games",
    key: "auragames",
    imgSrc: AuraGamesIcons,
  });

  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);

  const providersArray = [{ id: 5, name: "Aura", eventKey: "aura" }];
  const tabsArray = [
    {
      id: 12,
      name: "Improved Experience",
      key: "improvedExperience",
      imgSrc: ImprovedIcons,
    },
    {
      id: 13,
      name: "Card Games",
      key: "cardGames",
      imgSrc: CardGamesIcon,
    },
    {
      id: 14,
      name: "Virtual CardGames",
      key: "virtualCardGames",
      imgSrc: VirtualCardsGames,
    },
    {
      id: 15,
      name: "Instant Games",
      key: "instantGames",
      imgSrc: InstantGames,
    },
  ];

  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    // let filteredTabs = [];
    // for (let key in casinoSection) {
    //   Object.values(casinoSection[key]).map((values) => {
    //     if (
    //       typeof values !== "string" &&
    //       values?.providerName?.toLowerCase() === provider.eventKey
    //     ) {
    //       if (!filteredTabs.some((e) => e === key)) {
    //         filteredTabs.push(key);
    //       }
    //     }
    //   });
    // }
    // let tabToBeActive = "";
    // for (let i = 0; i < filteredTabs?.length; i++) {
    //   const filterValue = tabsArray.find((f) => f.key === filteredTabs[i]);
    //   if (filterValue) {
    //     tabToBeActive = filterValue;
    //     break;
    //   }
    // }
    // setCustomActiveTab(tabToBeActive);
    setCustomActiveTab({
      id: 1,
      name: "All Games",
      key: "allGames",
      imgSrc: AuraGamesIcons,
    });
    filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "evolution" ||
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo" ||
          f.providerName?.toLowerCase() === "aura"
      );
    }

    setAllGamesData(filteredValues);
  };
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <Container>
          <div className="pagesHeading">
            <h2 className="sectionTitle border-0">Aura Games</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>
          <div className="game-filter auragames-filter">
            <ul className="categories">
              {tabsArray?.map((tab, index) => {
                return (
                  <React.Fragment key={index}>
                    {((casinoSection?.[tab?.key] &&
                      Object.values(casinoSection?.[tab?.key]).some(
                        (s) =>
                          s?.providerName?.toLowerCase() ===
                          selectedProvider?.eventKey
                      )) ||
                      selectedProvider?.eventKey === "all" ||
                      tab?.key == "GamesAll") && (
                      <li
                        onClick={() => {
                          setCustomActiveTab(null);
                          if (tab.key === "allGames") {
                            filterAllGamesData();
                          }
                          setActiveTab(tab);
                        }}
                        // key={index}
                      >
                        <a
                          // href="javascript:void(0)"
                          className={getTabActiveClass(tab) ? "active" : ""}
                        >
                          <img src={tab?.imgSrc} alt="roulette" />
                          <span>{tab?.name}</span>
                        </a>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
          <div className="all-images AuraGamesSection">
            <ul>
              {casinoSection?.[activeTab?.key] &&
              activeTab?.key !== "allGames" &&
              customActiveTab?.key !== "allGames"
                ? Object.values(casinoSection?.[activeTab?.key]).map(
                    (casino, index) => {
                      return (
                        <React.Fragment key={index}>
                          {(selectedProvider?.eventKey === "all" ||
                            casino?.providerName?.toLowerCase() ===
                              selectedProvider.eventKey) && (
                            <li key={index}>
                              <a href={casino?.redirectUrl}>
                                <img src={casino?.imgUrl} />
                                <img src={play} className="play_button" />
                                <p>{casino?.name}</p>
                                <p className="hover_Name">{casino?.name}</p>
                              </a>
                              <span className="focus-border">
                                <i></i>
                              </span>
                            </li>
                          )}
                        </React.Fragment>
                      );
                    }
                  )
                : allGamesData &&
                  allGamesData.map((casino, index) => {
                    return (
                      <li key={index}>
                        <a href={casino?.redirectUrl}>
                          <img src={casino?.imgUrl} />
                          <img src={play} className="play_button" />
                          <p>{casino?.name}</p>
                          <p className="hover_Name">{casino?.name}</p>
                        </a>
                        <span className="focus-border">
                          <i></i>
                        </span>
                      </li>
                    );
                  })}
            </ul>
          </div>
        </Container>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AuraGames;
